import React from "react"

const Background = () => {
  return (
    <div className="area" >
      <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
      </ul>
    </div >
  )
}

export default Background